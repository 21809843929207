<template>
  <div class="main__content statistic">
    <div class="statistic__section statistic__section_first">
      <h4 class="title-h4 statistic__title">
        {{ $t('statistic.updated_period') }}
      </h4>
      <div class="statistic__period-block">
        <SelectField
          v-model="currentPeriod"
          :items="period"
          titleKey="title"
          valueToReturn="object"
          :title="$t('statistic.quarter')"
          placeholder=""
          isObjectType
          isRequired
          :is-error="$v.currentPeriod.$error"
          :isDisabled="!isStatisticSaved"
          class="statistic__period-select"
        />
        <SelectField
          v-model="currentYear"
          :items="getAvialablesYears"
          :title="$t('statistic.year')"
          placeholder=""
          isRequired
          :is-error="$v.currentYear.$error"
          :isDisabled="!isStatisticSaved"
          class="statistic__period-select"
        />
      </div>
    </div>
    <div class="statistic__section">
      <h4 class="title-h4 statistic__title required_el">
        {{ $t('statistic.export_template') }}
      </h4>
      <Button
        icon-name="download_template"
        isRightIcon
        :disabled="!isCanDownloadTemplate || !isStatisticSaved"
        @click.native="getTemplateLink"
      >
        {{ $t('buttons.download_template') }}
      </Button>
      <div class="statistic__notification" v-if="!isCanDownloadTemplate">
        {{ $t('statistic.export_template_info') }}
      </div>
    </div>
    <div class="statistic__section">
      <h4 class="title-h4 statistic__title required_el">
        {{ $t('statistic.import_updated_template') }}
      </h4>
      <FileUpload
        v-model="template"
        :max-size-k-b="fileMaxSize"
        :is-multiple="false"
        :valid-formats="validFormats"
        :is-error="$v.template.$error"
        :isDisabled="!isStatisticSaved"
        class="statistic__file-upload"
      />
    </div>
    <div class="statistic__buttons-block">
      <Button
        transparent
        :disabled="!isStatisticSaved"
        class="statistic__button"
        @click.native="
          $router.push({ name: 'content-administrator_statistics-list' })
        "
      >
        {{ $t('buttons.cancel') }}
      </Button>
      <Button
        :disabled="!isStatisticSaved"
        class="statistic__button"
        @click.native="saveStatisticHandler"
      >
        <template v-if="isStatisticSaved">
          {{ $t('buttons.save') }}
        </template>
        <LoadingSpinner :loaderText="$t('main.saving')" v-else />
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueI18n from '@/i18n';
import { required } from 'vuelidate/lib/validators';
import SelectField from '@/elements/SelectField.vue';
import FileUpload from '@/elements/FileUpload.vue';
export default {
  components: { SelectField, FileUpload },

  data() {
    return {
      currentPeriod: null,
      currentYear: null,
      template: null,
      validFormats: ['xlsx'],
      fileMaxSize: 5000,
      period: [
        { name: 'first_quarter', title: VueI18n.t('statistic.first_quarter') },
        {
          name: 'second_quarter',
          title: VueI18n.t('statistic.second_quarter'),
        },
        { name: 'third_quarter', title: VueI18n.t('statistic.third_quarter') },
        {
          name: 'fourth_quarter',
          title: VueI18n.t('statistic.fourth_quarter'),
        },
      ],
    };
  },

  mounted() {
    if (this.$route.params.activity === 'edit') {
      this.setPeriod();
    }
  },

  computed: {
    ...mapGetters(['getTemplateLinkContentAdmin', 'isStatisticSaved']),

    getAvialablesYears() {
      let i = 0;
      let startYear = 2022;
      let res = [];
      while (i < 50) {
        res.push(`${startYear + i}`);
        i++;
      }
      return res;
    },

    isCanDownloadTemplate() {
      return !!this.currentPeriod && !!this.currentYear;
    },

    getDataForTemplate() {
      return {
        year: this.currentYear,
        quarter: this.currentPeriod.name,
        type: this.$route.params.type_name,
      };
    },

    getDataForSave() {
      let data = new FormData();
      data.append('year', this.currentYear);
      data.append('quarter', this.currentPeriod.name);
      data.append('tourism_report', this.template[0]);
      data.append('type', this.$route.params.type_name);
      return data;
    },
  },

  methods: {
    async getTemplateLink() {
      await this.$store.dispatch(
        'getLinkToTemplateContentAdmin',
        this.getDataForTemplate
      );
      this.setlinkToDownloadTemplate();
    },

    setPeriod() {
      this.currentPeriod = this.period.find(
        (el) => el.name === this.$route.params.quarter
      );
      this.currentYear = `${this.$route.params.year}`;
    },

    setlinkToDownloadTemplate() {
      let link = document.createElement('a');
      link.href = this.getTemplateLinkContentAdmin;
      link.click();
    },

    saveStatisticHandler() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch(
          'uploadQuarterlyReportContentAdmin',
          this.getDataForSave
        );
      }
    },
  },

  validations: {
    currentPeriod: {
      required,
    },
    currentYear: {
      required,
    },
    template: {
      required,
    },
  },
};
</script>

<style lang="sass" scoped>
.statistic__section_first
  margin-top: 50px

.statistic__period-block
  display: flex
  @include xxs
    flex-direction: column

.statistic__period-select
  &:first-child
    margin-right: 60px
    @include xxs
      margin-right: 0
      margin-bottom: 50px

.statistic__title
  margin-bottom: 40px
  @include xxs
    line-height: 24px

.statistic__section
  margin-bottom: 10rem
  position: relative

.statistic__file-upload
  width: 30%
  min-width: 350px
  @include xxs
    min-width: 100%

.statistic__buttons-block
  display: flex
  justify-content: flex-end
  margin-bottom: 50px
  @include xs
    justify-content: center
  @include xxs
    flex-direction: column
    align-items: center

.statistic__button
  @include xs
    min-width: 220px
  @include xxs
    min-width: 290px
  &:first-child
    margin-right: 40px
    @include xs
      margin-right: 20px
    @include xxs
      margin-right: 0
      margin-bottom: 30px

.statistic__notification
  font-size: 12px
  color: $gray
  font-weight: 100
  margin-top: 10px
  position: absolute
</style>
